import { INIT_LOADER,HALT_LOADER
} from '../actions/types';

const loaderStatus = {isLoading:null,count:0}

export const initLoader= (state = loaderStatus, { type, payload }) => {
    
   switch (type){
       case INIT_LOADER:
           state.isLoading = true;
           state.count = state.count + 1;
           console.log(state.count,'initLoaderStats')
           return {
               ...state
           }
           case HALT_LOADER:
               state.count = state.count - 1;
               console.log(state.count,'haltLoaderStats')
               if(state.count<=0){
                   state.isLoading = false;
               }
           return {
               ...state
           }
       default:
           return state;
   }
}