import axios from "axios";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const instance = axios.create({
   baseURL: "/api",
});

instance.defaults.headers.common["Authorization"] = localStorage.getItem("token");
instance.defaults.headers.common["Content-Type"] = "application/json";
if (localStorage.getItem("languagePreference")) {
   instance.defaults.headers.common["languagePreference"] = localStorage.getItem("languagePreference");
}

const AxiosInterceptor = ({ children }) => {
   const history = useHistory();

   useEffect(() => {
      let requestCount = 0;

      const requestInterceptor = (config) => {
         requestCount++;
         disableAllButtons();
         return config;
      };

      const resInterceptor = (response) => {
         requestCount--;
         if (requestCount === 0) {
            enableAllButtons();
            // EnableButtons(); // Function to enable buttons
         }
         return response;
      };

      const errInterceptor = (error) => {
         requestCount--;
         if (requestCount === 0) {
            enableAllButtons();
            // EnableButtons(); // Function to enable buttons
         }

         if (
            error?.response?.data?.tokenExpired !== undefined &&
            error.response.data.tokenExpired === true
         ) {
            window.location.assign("/");
         }
         // if (error.response.status === 401) {
         //     history.push('/login');
         // }

         return Promise.reject(error);
      };

      const requestInterceptorId = instance.interceptors.request.use(requestInterceptor);
      const interceptor = instance.interceptors.response.use(resInterceptor, errInterceptor);

      return () => {
         instance.interceptors.request.eject(requestInterceptorId);
         instance.interceptors.response.eject(interceptor);
      };
   }, [history]);

   const disableAllButtons = () => {
      const buttons = document.getElementsByTagName("button");
      for (let i = 0; i < buttons.length; i++) {
         buttons[i].style.pointerEvents = "none";
      }
   };

   const enableAllButtons = () => {
      const buttons = document.getElementsByTagName("button");
      for (let i = 0; i < buttons.length; i++) {
         //  buttons[i].disabled = false;
         buttons[i].style.pointerEvents = "all";
      }
   };

   return children;
};

const electronAxios = axios.create({
   baseURL: "http://localhost:7272/merchant",
   timeout: 10000,
   headers: {
      Authorization:
         "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJzdGFyYnVja3MiLCJpc3MiOiJodHRwczpcL1wvYmV0YS5xdWV1ZWJ1c3Rlci5jbyIsImF1ZCI6Imh0dHBzOlwvXC9iZXRhLnF1ZXVlYnVzdGVyLmNvIiwiaWF0IjoxNzE0NDU0NDAzLCJleHAiOjE3MTcwNDY0MDMsImV4dHJhcGFyYW0iOiI2MDFmMjZmODMzZmYwYTRiY2NhZGNjYjJjYmY2NzU0NzkwM2Y2ZjJiIn0.Cb1SYDF6GlnOHhurkYa464_pSRTWU18cky7vcwA1jrA",
   },
});

export default instance;
export { AxiosInterceptor, electronAxios };
