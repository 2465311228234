import {ALL_PRODS_LIST, ALL_PRODS_DETAILS_LIST, GET_ALL_GROUP_FILTER_REQUEST, GET_ALL_GROUP_FILTER_SUCCESS, GET_ALL_GROUP_FILTER_FAILURE, GET_ALL_FILTER_REQUEST, GET_ALL_FILTER_SUCCESS, GET_ALL_FILTER_FAILURE} from '../actions/types';

const initialState = [{}]
const filterGroupListData = {
    data: [],
    isLoading: null,
};
const filterListData = {
  data: [],
  isLoading: null,
}
   

export const allProdsList = (state = initialState, {type, payload}) => {
    switch (type) {
        case ALL_PRODS_LIST:

            state = payload.data && payload.data.allProds.data;

            return payload.data && payload.data.allProds.data;
        default:
            return state;
    }
}

export const allProdsDetailsList = (state = initialState, {type, payload}) => {
    switch (type) {
        case ALL_PRODS_DETAILS_LIST:

            state = payload.data && payload.data.productsListChain.data;

            return payload.data && payload.data.productsListChain.data;
        default:
            return state;
    }
}

export const reservationListData = (state = initialState, {type, payload}) => {
    switch (type) {
        case "GET_RESERVATION_DATA":

            state = payload.data && payload.data.data

            return payload.data && payload.data.data
        default:
            return state;
    }
}

export const filterGroupList = (state = filterGroupListData,{ type, payload }) => {
    switch (type) {
      case GET_ALL_GROUP_FILTER_REQUEST:
        state.isLoading = true;
        return {
          ...state,
        };
      case GET_ALL_GROUP_FILTER_SUCCESS:
        state.isLoading = false;
        state.data = payload;
        return {
          ...state,
        };
      case GET_ALL_GROUP_FILTER_FAILURE:
        state.isLoading = false;
        return {
          ...state,
        };
      default:
        return state;
    }
};

export const reservationFilterList = (state = filterListData,{ type, payload }) => {
  switch (type) {
    case GET_ALL_FILTER_REQUEST:
      state.isLoading = true;
      return {
        ...state,
      };
    case GET_ALL_FILTER_SUCCESS:
      state.isLoading = false;
      state.data = payload;
      return {
        ...state,
      };
    case GET_ALL_FILTER_FAILURE:
      state.isLoading = false;
      return {
        ...state,
      };
    default:
      return state;
  }
};