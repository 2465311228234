import currencyMapping from './currencyMapping';
import countries from "../components/common/countries";

window.selectedSort = function(arr, selectedArr, key) {
    let i = -1;

    for (let j = 0; j < arr.length; j++) {
        if (selectedArr.includes(arr[j][key])) {
            let t = arr[i+1];
            arr[i+1] = arr[j];
            arr[j] = t;
            ++i;
        }
    }
}

window.isUndefinedEmpty = (value) => {
    return value === undefined || value === '' || (value instanceof String && value.trim() === '');
}

window.isUndefinedEmptyNaN = (value) => {
    return value === '' || value === undefined || isNaN(parseInt(value)) || (value instanceof String && value.trim() === '');
}

window.isUndefinedEmptyNull = (value) => {
    return value === '' || value === undefined || value === null || (value instanceof String && value.trim() === '');
}

function foreignSystem(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function indianSystem(x) {
    x=x.toString();
    let lastThree = x.substring(x.length-3);
    let otherNumbers = x.substring(0,x.length-3);
    if(otherNumbers != '')
        lastThree = ',' + lastThree;
    let res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    return res;
}

window.currencySanity = (value, currencyCode) => {
    if (value === undefined || value === null) return value;
    let v1 = value.toString().trim();
    
    let v2 = parseFloat(v1);
    if (isNaN(v2)) return value;

    let isNegative = v2 < 0;
    let absV2 = isNegative ? (-1*v2) : v2;

    let v3 = absV2.toFixed(2);

    let v3Split = v3.split('.');
    if (currencyCode === 'INR') {
        v3Split[0] = indianSystem(v3Split[0]);
    } else {
        v3Split[0] = foreignSystem(v3Split[0]);
    }

    let v4 = v3Split.join('.');

    let v5 = (isNegative ? '- ' : '') + currencyMapping(currencyCode) + (isNegative ? '' : ' ') + v4;
    return v5;
}
window.currencySymbol = (currencyCode) => {
    return Intl.NumberFormat("en-US", {style: "currency",currency: currencyCode}).formatToParts("").find(x => x.type === "currency").value;
}
window.toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

window.setReportGlobalFilters = (key, value) => {
    let filters = JSON.parse(sessionStorage.getItem('report_filters'));
    if (!filters) filters = {};
    filters[key] = value;
    sessionStorage.setItem('report_filters', JSON.stringify(filters));
}

window.getReportGlobalFilters = () => JSON.parse(sessionStorage.getItem('report_filters'))

window.sanitizePathname = (pathname) => {
    if(pathname.charAt(pathname.length-1) === '/') return pathname.substring(0, pathname.length-1);
    else return pathname;
}

window.splitIntoWords = (s) => {
    let output;
    output = s.charAt(0).toUpperCase() + s.substring(1);
    output = output.split(/(?=[A-Z])/).join(" ");
    return output;
}

window.intArrayIntersection = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false;
    let map = {};
    arr1.map((v, i) => {map[v] = i});
    for (let i = 0; i < arr2.length; i++) {
        if (map[arr2[i]] === undefined) return false;
    }
    return true;
}

window.getURLSearchObject = (searchString) => {
    let s = searchString.substring(1);
    let a = s.split('&');
    let output = {};
    a.map(v => {
        let ar = v.split('=');
        output[ar[0]] = ar[1];
    })
    return output;
}

window.debounce=(call,delay)=>{
    let timer;
    return function(...args){         // Call debounce into an variable with their args and call it where we want with some delay.
        if(timer)clearTimeout(timer)  
        timer=setTimeout(()=>{
          call();
        },delay);
    }
    
  }

window.roundDecimal = (num) => {
    let value = Number(num);
    return Math.round((value + Number.EPSILON) * 100) / 100;
}

window.getCallingCode = (countryName) => {
    let filteredCountryData = countries?.find((elem) => elem.name == countryName);
    return filteredCountryData.callingCodes;
}

