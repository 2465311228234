import { LOGIN, LOGIN_FAIL, CHECK_LOGIN, CHECK_LOGIN_FAIL,LOGOUT,OPEN_OTP, CLOSE_OTP, REGISTERATION_SUCCESS, GET_STARTED, SET_PREFFERNCE } from '../actions/types';
import axios from 'axios';

const initialState = {
    isLoggedIn: false,
    token:'',
    data:[],
    otp:'STAND_BY',
    registerationSuccess:false,
    serverOtp:null
}

export default (state = initialState, { type, payload }) => {
    switch (type){
        case LOGIN:
            

        state.isLoggedIn = true;
        state.token = payload.token
        state.data = payload.sessionData
        localStorage.setItem('token', payload.sessionData.qbSessionID)
        localStorage.setItem('languagePreference', payload.sessionData.languagePreference)
        axios.defaults.headers.common['Authorization'] = payload.sessionData.qbSessionID;
        return {
            ...state
        }
        case LOGIN_FAIL:
            

        state.isLoggedIn = false;
        state.data= payload;
        return {
            ...state
        }

        case CHECK_LOGIN:
            

        state.isLoggedIn = payload.status;
        state.token = payload.token
        state.data = payload.sessionData
        return{
            ...state
        }
        case CHECK_LOGIN_FAIL:
        localStorage.clear()
        return{
            ...state
        }
        case LOGOUT:
        localStorage.removeItem('token')
        localStorage.removeItem('report_filters')
        state.isLoggedIn = false
        return {
            ...state
        }
        case OPEN_OTP:
        state.otp = 'OPEN';
        state.serverOtp = payload.otp;
        return {
            ...state
        }
        case CLOSE_OTP:
        state.otp = 'CLOSE';
        state.registerationSuccess = payload.registerationSuccess;
        state.serverOtp = payload.serverOtp;
        return {
            ...state
        }
        case REGISTERATION_SUCCESS:
        state.otp = 'CLOSE';
        state.registerationSuccess = true;
        state.serverOtp = null;
        state.token = payload.token
        state.data = payload.sessionData
        localStorage.setItem('token', state.token)
        return {
            ...state
        }
        case GET_STARTED:
        state.isLoggedIn = true;
        state.registerationSuccess = false;
        state.otp = 'STAND_BY'
        return {
            ...state
        }
        case SET_PREFFERNCE: 
        state.data.dashboardPreference="V2"
        return {
            ...state
        }
        default:
            return state;
    }
}