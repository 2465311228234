import { INIT_LOADER, HALT_LOADER,FETCH_USERS_REQUEST,FETCH_USERS_SUCCESS,FETCH_USERS_FAILURE,FETCH_ROLES_MENU,
    VERIFY_NEWMOBILE_OTP_REQUEST,VERIFY_NEWMOBILE_OTP_SUCCESS,VERIFY_NEWMOBILE_OTP_FAILURE, USERNAME_AVAILABILITY,
    SEND_NEWMOBILE_OTP_REQUEST,SEND_NEWMOBILE_OTP_SUCCESS,SEND_NEWMOBILE_OTP_FAILURE, UPDATE_BASIC_INFO_REQUEST, UPDATE_BASIC_INFO_SUCCESS, UPDATE_BASIC_INFO_FAILURE,
    FETCH_ACTIVEROLES_MENU
 } from  '../actions/types';
const getUsersStatus =  {
    data:[],
    isLoading:null
}

const setNewMobileStatus =  {
    data:[],
    isLoading:null
}

const getRolesStatus =  {
    data:[],
    isLoading:null
}

const getActiveRolesStatus =  {
    data:[],
    isLoading:null
}

const getOtpVerifyStatus={
    data:[],
    isVerified:null
}

const getUsernameAvailStatus={
    data:[],
    isVerified:null
}

const getUpdateBasicInfoWeb = {
    data : [],
    isLoading:null
}

export const users= (state = getUsersStatus, { type, payload }) => {
    switch (type){
        case FETCH_USERS_REQUEST:
            state.isLoading = true;
            return {
                ...state
            }
            case FETCH_USERS_SUCCESS:
            state.isLoading = false;
            state.data=payload;
            return {
                ...state
            }
            case FETCH_USERS_FAILURE:
                state.isLoading = false;
                return {
                    ...state
                }
        default:
            return state;
    }
}

export const roles = (state = getRolesStatus, {type, payload}) => {
    switch (type) {
        case FETCH_ROLES_MENU:
            state.data=payload;
            return {
                ...state
            }
        default:
            return state;
    }
}

export const activeRoles = (state = getActiveRolesStatus, {type, payload}) => {
    switch (type) {
        case FETCH_ACTIVEROLES_MENU:
            state.data=payload;
            return {
                ...state
            }
        default:
            return state;
    }
}

export const setNewMobileOtp= (state = getOtpVerifyStatus, { type, payload }) => {
    switch (type){
        case VERIFY_NEWMOBILE_OTP_REQUEST:
            state.isVerified=null;
            return {
                ...state
            }
            case VERIFY_NEWMOBILE_OTP_SUCCESS:
            //  console.log(payload,'khamba')
            state.isVerified = true;
            state.data=payload;
            return {
                ...state
            }
            case VERIFY_NEWMOBILE_OTP_FAILURE:
                state.isVerified = false;
                return {
                    ...state
                }
        default:
            return state;
    }
}

export const setNewMobile= (state = setNewMobileStatus, { type, payload }) => {
    switch (type){
        case SEND_NEWMOBILE_OTP_REQUEST:
            state.isLoading=true;
            return {
                ...state
            }
            case SEND_NEWMOBILE_OTP_SUCCESS:
            state.isLoading = false;
            state.data=payload;
            return {
                ...state
            }
            case SEND_NEWMOBILE_OTP_FAILURE:
                state.isLoading = false;
                return {
                    ...state
                }
        default:
            return state;
    }
}

export const checkUsername = (state = getUsernameAvailStatus, {type, payload}) => {
    switch (type) {
        case USERNAME_AVAILABILITY:
            state.data=payload;
            return {
                ...state
            }
        default:
            return state;
    }
}

export const updateBasicInfoWeb = (state = getUpdateBasicInfoWeb, {type, payload}) => {
    switch (type){
        case UPDATE_BASIC_INFO_REQUEST:
            state.isLoading = true;
            return {
                ...state
            }
            case UPDATE_BASIC_INFO_SUCCESS:
            state.isLoading = false;
            state.data=payload;
            return {
                ...state
            }
            case UPDATE_BASIC_INFO_FAILURE:
                state.isLoading = false;
                return {
                    ...state
                }
        default:
            return state;
    }
}