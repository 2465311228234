import {
   GET_ALL_PRODUCT,
   COMBOS_DATA,
   SERVICES_DATA,
   MEMBERSHIPS_DATA,
   CATEGORY_DATA,
   PRODUCTS_DATA,
   GET_NUMBER_CART,
   ADD_CART,
   INCREASE_QUANTITY,
   DECREASE_QUANTITY,
   DELETE_CART,
   CUSTOMER_DATA,
   ADD_CUSTOMER,
   PROMOTIONS_DATA,
   HOLD_ORDER,
   REMOVE_HOLD_ORDER,
   RESET_CART,
   ADD_HOLD_ORDER_TO_CART,
   CHARGE_TAX_DATA,
   UNIT_DATA,
   PRODUCT_GROUP_DATA,
   MODIFIER_GROUPS_DATA,
   STORE_PAYMENT_DATA,
   APP_SETTINGS,
   UPDATE_CUSTOMER,
   BILLING_DEVICE_INFO,
   BILLING_TOGGLE_SHORTCUTS,
} from "../actions/types";

const billingCatalogue = {
   products: [],
   combos: [],
   services: [],
   memberships: [],
   categories: [],
   promotions: [],
   units: [],
   chargeTax: [],
   productGroup: [],
   modifierGroups: [],
   storePayment: [],
};

const billingCustomers = {
   customers: [],
};

const billingCart = {
   numberCart: 0,
   cartItems: [],
};

const billingHoldOrder = {
   holdCarts: [],
};

const billingAppSettings = {
   appSettings: [],
};

const deviceInfo = {
   deviceInfo: [],
};

const billingToggleShortcuts = {
   showBillingShortcuts: false,
};

export const billingCatalogueReducer = (state = billingCatalogue, action) => {
   switch (action.type) {
      case GET_ALL_PRODUCT:
         return {
            ...state,
            products: action.payload,
         };

      case COMBOS_DATA:
         return {
            ...state,
            combos: action.payload,
         };

      case SERVICES_DATA:
         return {
            ...state,
            services: action.payload,
         };

      case MEMBERSHIPS_DATA:
         return {
            ...state,
            memberships: action.payload,
         };

      case CATEGORY_DATA:
         return {
            ...state,
            categories: action.payload,
         };

      case PRODUCTS_DATA:
         return {
            ...state,
            products: action.payload,
         };

      case PROMOTIONS_DATA:
         return {
            ...state,
            promotions: action.payload,
         };

      case CHARGE_TAX_DATA:
         return {
            ...state,
            chargeTax: action.payload,
         };

      case UNIT_DATA:
         return {
            ...state,
            units: action.payload,
         };

      case PRODUCT_GROUP_DATA:
         return {
            ...state,
            productGroup: action.payload,
         };

      case MODIFIER_GROUPS_DATA:
         return {
            ...state,
            modifierGroups: action.payload,
         };

      case STORE_PAYMENT_DATA:
         return {
            ...state,
            storePayment: action.payload,
         };

      default:
         return state;
   }
};

export const billingCustomerReducer = (state = billingCustomers, action) => {
   switch (action.type) {
      case CUSTOMER_DATA:
         return {
            ...state,
            customers: action.payload,
         };

      case ADD_CUSTOMER:
         const allCustomers = [action.payload, ...state.customers];
         return {
            ...state,
            customers: allCustomers,
         };

      case UPDATE_CUSTOMER:
         let customers = JSON.parse(JSON.stringify(state.customers));
         let modifiedCustomers = customers?.map((cust, idx) => {
            let elem = JSON.parse(JSON.stringify(cust));
            if (elem.customerID == action.payload.customerID) {
               elem = {
                  ...action.payload,
                  balance: cust.balance,
                  no: action.payload.no || idx + 1,
               };
            }
            return elem;
         });
         return {
            ...state,
            customers: modifiedCustomers,
         };

      default:
         return state;
   }
};

export const billingGetAppSettings = (state = billingAppSettings, action) => {
   switch (action.type) {
      case APP_SETTINGS:
         return {
            ...state,
            appSettings: action.payload,
         };

      default:
         return state;
   }
};

export const billingCartReducer = (state = billingCart, action) => {
   switch (action.type) {
      case GET_NUMBER_CART:
         return {
            ...state,
         };

      case ADD_CART:
         // This block is adding the first item in the cart ( Cart is empty! )
         if (state.numberCart == 0) {
            let cart = {
               ...action.payload,
               id: action.payload.productID || action.payload.comboProductID,
               quantity: action.payload.quantity || 1,
               name: action.payload.productName || action.payload.comboName,
               image: action.payload.imageLocation || "",
               price:
                  action.payload.price ||
                  action.payload.comboPrice ||
                  action.payload.productPrice ||
                  0,
               barcode: action.payload.barcode,
            };
            state.cartItems.push(cart);
         } else {
            // If item is already present in the cart ( with modifier )
            let check = false;
            if (
               !action.payload.isAddedNew &&
               !action.payload.isProductBatched &&
               !action.payload.isOpenItem &&
               !action.payload.isMembershipProd
            ) {
               state.cartItems.map((item, key) => {
                  const modifiers = item.modifiersList,
                     comboProducts = item.comboProductsList,
                     { modifiersList, comboProductsList } = action.payload;
                  if (
                     (item.id == action.payload.productID &&
                        JSON.stringify(modifiers) == JSON.stringify(modifiersList)) ||
                     (item.id == action.payload.comboProductID &&
                        JSON.stringify(comboProducts) == JSON.stringify(comboProductsList))
                  ) {
                     if (action.payload.quantity) {
                        state.cartItems[key].quantity += action.payload.quantity;
                     } else {
                        state.cartItems[key].quantity++;
                     }
                     check = true;
                  }
               });
            } else if (action.payload.isProductBatched && !action.payload.isOpenItem) {
               // If item is batched and already present in the cart
               state.cartItems.map((item, key) => {
                  if (action.payload.batchVariantID == item.batchVariantID) {
                     state.cartItems[key].quantity++;
                     check = true;
                  }
               });
            } else if (action.payload.isMembershipProd) {
               state.cartItems.map((item, key) => {
                  if (
                     action.payload.productID == item.productID &&
                     JSON.stringify(action.payload.membershipTracker) ==
                        JSON.stringify(item.membershipTracker)
                  ) {
                     state.cartItems[key].quantity++;
                     check = true;
                  }
               });
            }
            // If item is not present in the cart ( with or without modifier )
            if (!check) {
               let _cart = {
                  ...action.payload,
                  id: action.payload.productID || action.payload.comboProductID,
                  quantity: action.payload.quantity || 1,
                  name: action.payload.productName || action.payload.comboName,
                  image: action.payload.imageLocation || "",
                  price:
                     action.payload.price ||
                     action.payload.comboPrice ||
                     action.payload.productPrice ||
                     0,
                  barcode: action.payload.barcode || "",
               };
               state.cartItems.unshift(_cart);
            }
         }
         return {
            ...state,
            numberCart: action.payload.quantity
               ? state.numberCart + action.payload.quantity
               : state.numberCart + 1,
         };

      case INCREASE_QUANTITY:
         state.numberCart++;
         state.cartItems[action.payload].quantity++;
         return {
            ...state,
         };

      case DECREASE_QUANTITY:
         var quantity = state.cartItems[action.payload].quantity;
         if (quantity > 1) {
            state.numberCart--;
            state.cartItems[action.payload].quantity--;
         }
         return {
            ...state,
         };

      case DELETE_CART:
         // Removing an item from the cart
         let quantity_ = state.cartItems[action.payload].quantity;
         const { cartItems } = state;
         let copy = [...cartItems];
         const rem = copy.splice(action.payload);
         if (rem.length === 1) {
            return {
               ...state,
               numberCart: state.numberCart - quantity_,
               cartItems: copy,
            };
         }
         rem.shift();
         let newArr = [...copy, ...rem];
         return {
            ...state,
            numberCart: state.numberCart - quantity_,
            cartItems: newArr,
         };

      case RESET_CART:
         // Removing all items from the cart
         return {
            numberCart: 0,
            cartItems: [],
         };

      case ADD_HOLD_ORDER_TO_CART:
         let items = action.payload;
         const totalQuantity = items.reduce((a, e) => {
            return a + Number(e.quantity);
         }, 0);
         return {
            ...state,
            cartItems: items,
            numberCart: totalQuantity,
         };

      default:
         return state;
   }
};

export const billingHoldOrderReducer = (state = billingHoldOrder, action) => {
   let orders;
   switch (action.type) {
      case HOLD_ORDER:
         orders = state.holdCarts;
         orders.unshift(action.payload);
         return {
            ...state,
            holdCarts: orders,
         };
      case REMOVE_HOLD_ORDER:
         orders = state.holdCarts;
         orders.splice(action.payload, 1);
         return {
            ...state,
            holdCarts: orders,
         };
      default:
         return state;
   }
};

export const billingCheckConnection = (state = deviceInfo, action) => {
   switch (action.type) {
      case BILLING_DEVICE_INFO:
         if (action.payload) {
            return {
               ...state,
               deviceInfo: [action.payload],
            };
         } else {
            return {
               ...state,
               deviceInfo: [],
            };
         }
      default:
         return state;
   }
};

export const billingShortcuts = (state = billingToggleShortcuts, action) => {
   switch (action.type) {
      case BILLING_TOGGLE_SHORTCUTS:
         let modalStatus = state.showBillingShortcuts;
         return { ...state, showBillingShortcuts: !modalStatus };
      default:
         return state;
   }
};
